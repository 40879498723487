import React from "react"
import styled, { keyframes } from "styled-components"
import VanillaTilt from "vanilla-tilt"

import { device } from "../utils/device"
import Github from "../images/github.svg"
import Linkedin from "../images/linkedin.svg"
import Medium from "../images/medium.svg"
import Twitter from "../images/twitter.svg"
import Instagram from "../images/instagram.svg"
import Youtube from "../images/youtube.svg"
import Spotify from "../images/spotify.svg"
import { Container, Layout, SEO } from "../components"

const gradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
  25% {
    background-position: 50% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
	75% {
		background-position: 50% 50%;
	}
  100% {
    background-position: 0% 50%;
  }
`

const StyledLink = styled.a`
  width: 26px;
  height: 26px;
  display: inline-flex;

  svg {
    will-change: transition;
    transition-property: filter;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    filter: grayscale(1);
  }

  &:hover {
    svg {
      filter: grayscale(0);
    }
  }
`

const SocialLink = ({ title, href, children }) => (
  <StyledLink
    target="_blank"
    rel="noopener noreferrer"
    aria-label={title}
    role="link"
    title={title}
    href={href}
  >
    {children}
  </StyledLink>
)

const SocialListLink = styled.div`
  display: flex;

  a {
    margin: 0 10px;
  }
`

const SocialContainer = styled.div`
  display: flex;
  margin: 30px 0;
  flex-direction: column;

  .about-social-block {
    margin-bottom: 30px;
    display: flex;
    flex: 1 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      margin-bottom: 15px;
    }
  }

  @media ${device.mobileL} {
    flex-direction: row;
  }
`

const Subtitle = styled.h4`
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -2px;
  text-align: center;
  margin-bottom: 40px;
  background-size: 300% 100%;
  animation: ${gradient} 10s ease infinite;
  background-image: linear-gradient(
    to left,
    #feac5e,
    #f5609a,
    var(--color-primary),
    #23d5ab
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  .keyword {
    font-weight: 700;
  }

  @media ${device.laptop} {
    font-size: 66px;
    letter-spacing: -4px;
    margin-bottom: 60px;
  }
`

export default () => {
  const titleRef = React.useRef()

  React.useEffect(() => {
    const titleNode = titleRef.current
    VanillaTilt.init(titleNode, {
      max: 25,
      speed: 400,
      glare: true,
      "max-glare": 0,
    })
    return () => titleNode.vanillaTilt.destroy()
  }, [])

  return (
    <Layout>
      <SEO title="Jonas Antonelli" />
      <Container>
        <Subtitle ref={titleRef}>Musician and Software Engineer</Subtitle>
        <SocialContainer>
          <div className="about-social-block">
            <h4>Musician</h4>
            <SocialListLink>
              <SocialLink title="Spotify" href="https://bit.ly/jon-elli">
                <Spotify fill="#1ED760" width="26" height="26" />
              </SocialLink>
              <SocialLink title="Youtube" href="https://bit.ly/JonElli-YouTube">
                <Youtube fill="#FF0000" width="26" height="26" />
              </SocialLink>
              <SocialLink
                title="Instagram"
                href="https://www.instagram.com/jon.elli"
              >
                <Instagram fill="#E4405F" width="26" height="26" />
              </SocialLink>
            </SocialListLink>
          </div>

          <div className="about-social-block">
            <h4>Software Engineer</h4>
            <SocialListLink>
              <SocialLink
                title="Github"
                href="https://github.com/jonasantonelli"
              >
                <Github fill="#181717" width="26" height="26" />
              </SocialLink>
              <SocialLink
                title="Linkedin"
                href="https://www.linkedin.com/in/jonasantonelli/"
              >
                <Linkedin fill="#0077B5" width="26" height="26" />
              </SocialLink>
              <SocialLink
                title="Medium"
                href="https://medium.com/@jonasantonelli"
              >
                <Medium fill="#12100E" width="26" height="26" />
              </SocialLink>
              <SocialLink
                title="Twitter"
                href="https://twitter.com/jonasantonelli"
              >
                <Twitter fill="#1DA1F2" width="26" height="26" />
              </SocialLink>
            </SocialListLink>
          </div>
        </SocialContainer>
      </Container>
    </Layout>
  )
}
